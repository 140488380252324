<template>
  <form :class="['filter-options', dropdown ? 'dropdown' : '']">

    <div v-if="dropdown && false" class="form-group">
      <div class="w-100">
        <label class="w-100">REGION</label>
        <destination-select @changedSelectedDestination="changeSelectedDestination"/>
      </div>
    </div>

    <h6 v-if="dropdown" class="title">Kategorien</h6>

    <label v-for="(category, index) in categories" :key="index" :class="['checkbox', isSelected(category.name) ? 'is-selected' : '']">
      <input type="checkbox" @click="selectCategory(category)" />
      {{ category.name }}
    </label>

    <div v-if="dropdown" class="btn btn-sm btn-block mt-3" @click="closeThis">Schließen</div>
  </form>
</template>

<script>
  export default {
    name: 'FilterOptions',
    components: {
      DestinationSelect: () => import('@/components/controls/DestinationSelect'),
    },
    props: {
      dropdown: {
        type: Boolean,
      default: false
      },
    },
    data: () => ({
      destination: ""
    }),
    computed:{
      destinations(){
        return this.$store.getters.getDestinations;
      },
      selectedDestination(){
        return this.$store.getters.getSelectedDestination;
      },
      categories(){
        const cats = this.$store.getters.getCategories;
        return cats.slice(0, -1);
      },
      selectedCategories(){
        return this.$store.getters.getSelectedCategories;
      },
    },
    methods: {
      isSelected(category){
        if(this.selectedCategories !== null){
          return this.selectedCategories.indexOf(category)>=0?true:false;
        }
        return null;
      },
      changeSelectedDestination(){
        this.$emit('changedSelectedDestination',this.destination);
      },
      closeThis(){
        this.$emit('closeThis');
      },
      selectCategory(category) {
        var categories = new Array();
        if(this.selectedCategories !== null){
          categories = this.selectedCategories;
        }

        /*check if the category is currently selected*/
        if(this.isSelected(category.name)){
          /*it is selected, so we'll have to find it*/
          var index = categories.indexOf(category.name);
          /*and remove it*/
          categories.splice(index, 1);
        }
        else{
          /*it's not, so push it onto the stack of selectedCategories*/
          console.log(category.name);
          categories.push(category.name);
        }
        this.$store.commit('SET_SELECTED_CATEGORIES',categories);
        this.$emit('changedSelectedCategories',categories);
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '@/scss/_variables.scss';
  @import '@/scss/_mixins.scss';

  .filter-options {
    background: transparent;

    &.dropdown {
      position: absolute;
      width: 100%;
      height: auto;
      max-height: 420px;
      z-index: 100;
      padding: 20px 40px;
      background: $white-color;
      border-bottom-left-radius: 25px;
      border-bottom-right-radius: 25px;
      box-shadow: 0 2px 3px rgba($color: $dark-color, $alpha: 0.15);
      overflow-y: auto;
      @include responsive('xs') {
        padding: 20px 10px;
      }
      @include hide-scrollbar;
      .title {
        text-transform: uppercase;
        color: $text-color;
        margin-bottom: 10px;
      }
    }

    .checkbox {
      display: inline-block;
      background-color: $white-color;
      color: $text-color;
      text-transform: uppercase;
      padding: 6px 12px;
      font-weight: 700;
      border-radius: 50px;
      font-size: 12px;
      cursor: pointer;
      border: 1px solid rgba($color: $dark-color, $alpha: 0.15);

      input[type='checkbox'] {
        padding: 0;
        width: initial;
        height: initial;
        display: none;
      }
      margin-right: 10px;
      &.is-selected {
        background-color: $primary;
        border-color: $primary;
        color: $white-color;
        padding-right: 35px;
        &::after {
          position: absolute;
          margin-left: 8px;
          font-family: 'Material Icons';
          content: '\e876';
          font-size: 18px;
          line-height: 1;
        }
      }
    }
  }
</style>
